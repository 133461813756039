var CONFIRMATION_MESSAGE = 'Looks like you have unsaved changes. Are you sure you want to navigate away?'
window.hasFormChanges = false

window.subscribeToFormChanges = function () {
    $("form input[type='text'][name!='search'], form input[type='radio'], form input[type='number'], form textarea").on('change', function() {
        window.hasFormChanges = true
    });
}

document.addEventListener("turbolinks:load", function(event) {
    window.subscribeToFormChanges();

    $(window).on('beforeunload', function (event) {
        if (window.hasFormChanges) {
            event.returnValue = CONFIRMATION_MESSAGE // This is how to set the confirmation o some browser
            return event.returnValue // For Chrome return the message
        }
    })

    // When submitting a form, we are navigating away from the page naturally,
    // and the beforeunload event is triggered, we unbind the event the event so that
    // the form can be submitted without showing the prompt
    $('form, button[type="submit"]').submit(function() {
        $(window).unbind('beforeunload'); // unbind when submitting a form
    });

    // There are some anchor tags that are actually not navigating but rather trigger some
    // javascript actions. We filter them out here
    $('a:not([data-view-action]):not([data-remote]):not([data-method]):not([href="javascript:void(0)"]):not([href="#"])').on('click', function (event) {
        if ($(this).html().toLowerCase().includes('delete')) {
            $(window).unbind('beforeunload'); // unbind when deleting a resource
        } else if (window.hasFormChanges) {
            if (confirm(CONFIRMATION_MESSAGE)) window.hasFormChanges = false
            else event.preventDefault()
        }
    })

    $('.modal').on('hide.bs.modal', function (event) {
        if (window.hasFormChanges) {
            if (confirm(CONFIRMATION_MESSAGE)) window.hasFormChanges = false
            else event.preventDefault()
        }
    })
})