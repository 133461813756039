// Can reuse this elsewhere, refer to app/views/conversations/partials/_sidebar.html.haml for usage
document.addEventListener("turbolinks:load", function (event) {
  if ($('#clearable-search').length) {

    text_input = $('#clearable-search')
    clearButton = $('#clearSearch')
    checkInput.call();

    text_input.on('input', checkInput)
    clearButton.on('click', function clearSearch(e) {
      text_input.val(null);
      // $('#submitButton')[0].form.submit();
      $('#form-root').submit();
    });

    function checkInput(e) {
      if (text_input.val().length > 0) {
        clearButton.removeClass('d-none');
      } else if (text_input[0].value.length == 0) {
        clearButton.addClass('d-none');
      }
    }
  }
});