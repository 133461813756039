var init_filestack = function() {

  if($('#filestack-view').length) {

    // This is to reset the security policy for uploading documents
    // 60 mins in milliseconds
    setTimeout(function(){ location.reload(); }, 60 * 60000);

    const clientOptions = {
      security: {
        policy: gon.filestack.policy,
        signature: gon.filestack.signature
      }
    }

    const client = filestack.init(gon.filestack.api_key, clientOptions);

    var post_path = $('#filestack-view').data('attachment-path');

    // TODO: configure on filestack dashboard & add: ['dropbox', 'gmail'] + google photos?
    var options = {
      uploadInBackground: false,
      fromSources: ['local_file_system', 'video', 'audio', 'webcam', 'googledrive', 'onedrive'],
      maxFiles: 20,
      onFileSelected: function(file) {
          // If you throw any error in this function it will reject the file selection.
          // The error message will be displayed to the user as an alert.
          if (file.size > 200000000) {
              alert(gon.filestack.file_too_big);
              throw new Error(gon.filestack.file_too_big);
          }
      },
      onFileUploadFailed: function(file, error) {
        alert("There was an error uploading " + file.filename + ". Try again or try a different file format?");
      },
      onFileUploadFinished: function(file) {
        // Upload each file individually
        $.ajax({
          type: "POST",
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          url: post_path,
          data: file,
          error: function(data) {
            alert(gon.filestack.error);
            return false;
          }
        });

      },
      onUploadDone: function(files){
        // All done! nothing to do here.
        // Content has been refreshed via the POST requests above
      }
    };

    var picker = client.picker(options);

    $('.upload-files').click(function(e){
      e.preventDefault();
      picker.open();
    });

  }

}


document.addEventListener("turbolinks:load", function() {
  init_filestack();

  // individual submission personal attachment
  $('#assignmentModal').on('show.bs.modal', function (e) {
    init_filestack();
  });
});

