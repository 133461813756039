import React, { useState } from 'react';
import PropTypes from 'prop-types';

const StudentConfidence = ({ userAssignment, setValue }) => {
  const [confidence, setConfidence] = useState(userAssignment.student_confidence);
  return (
      <div className="d-grid w-100">
          <div className="btn-group btn-group-toggle" role="group" data-toggle="buttons">
              <input
                name='student_confidence'
                id="confidence-1"
                className="me-2 btn-check"
                value={1}
                type="radio"
                checked={confidence === 1}
                onChange={(e) => {
                    setValue('student_confidence', 1, { shouldValidate: true });
                    setConfidence(1);
                }}
                aria-label="Radio button for following text input" />
              <label className={`btn btn-outline-primary ${userAssignment.student_confidence == 1 ? 'active' : ''}`} htmlFor="confidence-1">
                <img src={require(`../../../assets/images/emojis/low_confidence${confidence == 1 ? '.svg' : '_disabled.svg'}`)} width="25" height="25" />
                <br />
                <small>Low</small>
              </label>

              <input
                      name='student_confidence'
                      id="confidence-2"
                      className="me-2 btn-check"
                      value={2}
                      type="radio"
                      checked={confidence === 2}
                      onChange={(e) => {
                          setValue('student_confidence', 2, { shouldValidate: true });
                          setConfidence(2);
                      }}
                      aria-label="Radio button for following text input" />
              <label className={`btn btn-outline-primary ${userAssignment.student_confidence == 2 ? 'active' : ''}`} htmlFor="confidence-2">
                  <img src={require(`../../../assets/images/emojis/neutral_confidence${confidence == 2 ? '.svg' : '_disabled.svg'}`)} width="25" height="25" />
                  <br />
                  <small>Neutral</small>
              </label>
            
              <input
                      name='student_confidence'
                      id="confidence-3"
                      className="me-2 btn-check"
                      value={3}
                      type="radio"
                      checked={confidence === 3}
                      onChange={(e) => {
                          setValue('student_confidence', 3, { shouldValidate: true });
                          setConfidence(3);
                      }}
                      aria-label="Radio button for following text input" />
              <label className={`btn btn-outline-primary ${userAssignment.student_confidence == 3 ? 'active' : ''}`} htmlFor="confidence-3">
                  <img src={require(`../../../assets/images/emojis/high_confidence${confidence == 3 ? '.svg' : '_disabled.svg'}`)} width="25" height="25" />
                  <br />
                  <small>High</small>
              </label>
          </div >
      </div>
  )
}

StudentConfidence.propTypes = {
  userAssignment: PropTypes.shape({}).isRequired,
};

export default StudentConfidence;