document.addEventListener('turbolinks:load', function(event) {
  var isFormModified = false;

  function setisFormModified(selector) {
    var hasData = $(selector).filter(function() {
      return $.trim(this.value).length;
    }).length;

    if (hasData) {
      isFormModified = true;
    } 
  }

  $('.edit_assignment_objective').on('change', function() {
    setisFormModified('.edit_assignment_objective textarea');
  });

  window.addEventListener('beforeunload', function(e) {
    if (isFormModified) {  
      e.preventDefault();

      e.returnValue = '';
    }
  });

  // Reset when submit button is clicked
  $("input[name='commit']").on('click', function() {
    isFormModified = false;
    window.onbeforeunload = null;
});

  document.addEventListener('turbolinks:click', function (e) {
    if (isFormModified) {
      e.preventDefault();
    }
  });
});
