import WebViewer from '@pdftron/webviewer'

var initPdftron = function () {
  var viewer = $('#doc-viewer')
  if (viewer.length < 1) return

  var savingStatusWrapper = $('#attachmentPreviewModal .saving-status')
  var savingStatusText = savingStatusWrapper.find('.text')

  var file = viewer.data('file')
  var extension = viewer.data('extension')
  var annotationData = viewer.data('annotation-data')
  var annotationUpdatePath = viewer.data('annotation-update-path')
  var download = viewer.data('download')
  var authorName = viewer.data('author-name')
  var userId = viewer.data('author-id')
  var name = viewer.data('name')
  var attachmentId = viewer.data('attachment-id')

  WebViewer(
    {
      annotationUser: userId,
      path: '/packs/js/assets/webviewer',
    },
    document.getElementById('doc-viewer')
  ).then((instance) => {
    const { Core, UI } = instance
    const { documentViewer, annotationManager } = Core
    UI.loadDocument(file, { filename: name, extension: extension })
    viewer.data('can-annotate') == '1'
      ? annotationManager.disableReadOnlyMode()
      : annotationManager.enableReadOnlyMode()

    documentViewer.addEventListener('documentLoaded', () => {
      // call methods relating to the loaded document
      // if rendering in preview modal slider
      $('#doc-viewer').css({ height: '90vh' })

      // load the annotations
      annotationManager.importAnnotations(annotationData)

      if (download) {
        downloadFile(instance)
      }
    })

    annotationManager.addEventListener(
      'annotationChanged',
      (annotations, action, { imported }) => {
        // If the event is triggered by importing then it can be ignored
        // This will happen when importing the initial annotations from the server or individual changes from other users
        if (imported) return

        // saving status indicator
        savingStatusWrapper.fadeIn()
        savingStatusText.text('Saving...')

        // set user id and name for the annotation
        const annot = annotations[0]

        annot.setCustomData('userId', annotationManager.getCurrentUser())
        annot.setCustomData('authorName', authorName)

        const xfdfString = annotationManager.exportAnnotations()
        xfdfString.then((data) =>
          updateAnnotations(data, annotationUpdatePath, savingStatusText)
        )
      }
    )

    annotationManager.setAnnotationDisplayAuthorMap((annotation) => {
      if (typeof annotation === 'string') return authorName
      else return annotation.getCustomData('authorName')
    })
  })
}

var updateAnnotations = function (
  data,
  annotationUpdatePath,
  savingStatusText
) {
  $.ajax({
    url: annotationUpdatePath,
    type: 'PUT',
    data: { data: data },
    dataType: 'json',
    success: function (data, text) {
      savingStatusText.text('Saved!')
      reloadUserAssignment()
    },
    error: function (request, status, error) {
      savingStatusText.text('Error saving... please try again?')
    },
  })
}

var downloadFile = function (instance) {
  instance.downloadPdf({
    includeAnnotations: true,
    flatten: true,
  })
}

var reloadUserAssignment = function () {
  var current_user_assignment_view_id = $('#assignmentModal')
    .find('[data-user-assignment]')
    .data('user-assignment')
  var user_assignment = $(
    `#student-submissions .card[data-user-assignment=${current_user_assignment_view_id}]`
  )
  var url = user_assignment.find('.btn-primary').attr('href')

  if (url) {
    $.getScript(url)
  }
}

document.addEventListener('turbolinks:load', function (event) {
  initPdftron()

  $('#attachmentPreviewModal').on('show.bs.modal', function (e) {
    initPdftron()
  })
})
