import { createAttachment, submitUserAssignment, getAttachment, destroyAttachment, download } from "./api";

export const submitAssignment = async (params, payload) => {
  try {
    const response = await submitUserAssignment(params, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addAttachment = async (params, file) => {
  try {
    const response = await createAttachment(params, file);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAttachment = async params => {
  try {
    const response = await getAttachment(params);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAttachment = async params => {
  try {
    await destroyAttachment(params);
    return;
  } catch (error) {
    throw error;
  }
};

export const downloadAttachment = async params => {
  try {
    const response = await download(params);
    return response;
  } catch (error) {
    throw error;
  }
};
