import React from "react";
import PropTypes from 'prop-types';
import Form from "./Form";

const UserAssignment = ({ orgSlug, classroomSlug, assignmentSlug, userAssignment, embeddableHint}) => <>
  <Form
    orgSlug={orgSlug}
    classroomSlug={classroomSlug}
    assignmentSlug={assignmentSlug}
    userAssignment={userAssignment}
    embeddableHint={embeddableHint}
  />
</>;

UserAssignment.propTypes = {
  orgSlug: PropTypes.string.isRequired,
  classroomSlug: PropTypes.string.isRequired,
  assignmentSlug: PropTypes.string.isRequired,
  userAssignment: PropTypes.shape({}).isRequired,
};

export default UserAssignment;
