import intlTelInput from 'intl-tel-input';
import * as EasyMDE from 'easymde';
window.EasyMDE = EasyMDE;

document.addEventListener("turbolinks:load", function (event) {
  // Google tag manager push page views
  var url = event.data.url;
  dataLayer.push({
    'event': 'pageView',
    'virtualUrl': url
  });


  // Forms error state remove on change
  $('input.is-invalid').bind("change", function () {
    $(this).removeClass('is-invalid');
  });

  // File upload field bootstrap show file name
  $('.custom-file input').change(function (e) {
    var files = [];
    for (var i = 0; i < $(this)[0].files.length; i++) {
      files.push($(this)[0].files[i].name);
    }
    $(this).next('.custom-file-label').html(files.join(', '));
  });


  // Tel Input
  $("input[type='tel']").each(function () {
    var input = this;
    intlTelInput(input, {
      // initialCountry: "auto", // does not work sometimes
      initialCountry: 'ie',
      hiddenInput: $(this).data('name'),
      autoHideDialCode: false,
      separateDialCode: true,
      nationalMode: false,
      onlyCountries: ["ie"],
      preferredCountries: [],
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.8/js/utils.js"
    });
  });

  // Markdown 
  $('textarea.markdown').each(function () {
    new EasyMDE({
      element: this,
      placeholder: "Write something here...",
      hideIcons: ["guide", "fullscreen", "side-by-side", "image"]
    });
  });



  // Nested forms prepend new fields
  $("[data-form-prepend]").click(function (e) {
    var obj = $($(this).attr("data-form-prepend"));
    obj.find("input, select, textarea").each(function () {
      $(this).attr("name", function () {
        return $(this)
          .attr("name")
          .replace("new_record", new Date().getTime());
      });
    });
    obj.insertBefore(this);
    obj.find('input').focus();
    return false;
  });


  // Remove unsaved (not persisted yet) assignment objective fields
  $('#assignment-objectives').on('click', 'a.remove-unsaved-assignment-objective', function (e) {
    e.preventDefault();
    $(this).closest('.row').remove();
  });

  // On "enter" key press, do not submit form. Instead add another goal.
  $('#assignment-objectives').on('keypress', '.assignment_assignment_objectives_name input.form-control, .integrations_google_classroom_assignment_assignment_objectives_name input.form-control, .integrations_microsoft_teams_assignment_assignment_objectives_name input.form-control', function (e) {
    var key = e.which;
    if (key == 13)  // the enter key code
    {
      e.preventDefault();

      var empty_val_inputs = $('#assignment-objectives .assignment_assignment_objectives_name input, #assignment-objectives .integrations_google_classroom_assignment_assignment_objectives_name input, #assignment-objectives .integrations_microsoft_teams_assignment_assignment_objectives_name input').filter(function () {
        return this.value === '';
      });

      if (empty_val_inputs.length) {
        empty_val_inputs.filter(':first').focus();
      } else {
        $('#add-new-assignment-objective').click();
      }
    }
  });


  // Prev & Next links on assignment modal
  var current_user_assignment_view_id = function () {
    return $('#assignmentModal').find('[data-user-assignment]').data('user-assignment');
  }

  var new_user_assignment_modal_card = function (current_card, action) {
    var new_card;

    if (action == 'prev') {
      new_card = current_card.parent().prev().find('.card[data-user-assignment]');
    } else {
      new_card = current_card.parent().next().find('.card[data-user-assignment]');
    }

    // if this is the card on any end, get the card from the other end
    var wrapperId = current_card.closest('.row').attr('id');
    if (new_card.length == 0) {
      if (action == 'prev') {
        new_card = $('#' + wrapperId + ' .card[data-user-assignment]:last');
      } else {
        new_card = $('#' + wrapperId + ' .card[data-user-assignment]:first');
      }
    }

    return new_card;
  }

  var simulateView = function (current_element) {
    var button = $(current_element);
    var button_val = $(current_element).html();

    // for updating text to show loading
    button.html('Loading...');
    var current_card = $('.card[data-user-assignment=' + current_user_assignment_view_id() + ']')

    var action = $(current_element).data('view-action');

    // make ajax call simulating what the "view" button on card does
    $.getScript(
      new_user_assignment_modal_card(current_card, action)
        .find('.btn-primary')
        .attr('href')
    ).always(function () {
      // after success, put back default button value back
      button.html(button_val);
    });
  }

  $('#assignmentModal').on('click', 'a[data-view-action]', function (e) {
    e.preventDefault();
    simulateView(this)
  });


  // Sharing modal
  $("a[data-sharing-modal]").click(function (e) {
    e.preventDefault();

    var sharing_url = $(this).data('sharing-url') || window.location.href;
    $('#sharing-url').val(sharing_url);

    $('#sharingModal').modal('show');
  });

  // Student reflection previews
  $("a[data-preview-modal]").click(function (e) {
    e.preventDefault();

    $("#preview_modal_title").text($(this).data('preview-title'));
    $("#preview_modal_description").text($(this).data('preview-description'));
    $("#preview_modal_image").attr("src", $(this).data('image-path'));

    $('#preview_modal').modal('show');
  });


  // Model inception z-index fix
  // https://stackoverflow.com/a/24914782
  $(document).on('show.bs.modal', '.modal', function () {
    var zIndex = 1050 + (10 * $('.modal:visible').length);

    $(this).css('z-index', zIndex);
    setTimeout(function () {
      $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    }, 0);
  });

});
