document.addEventListener("turbolinks:load", function(event) {

  //
  // Variables
  //

  var autoLoader = $('.auto-loader');

  //
  // Functions
  //

  function init() {
    autoLoader.each(function(index, currentElement) {
      currentElement = $(currentElement);

      // hide the element we want to show later
      $(currentElement.data('to-show')).hide();

      // random titles
      var titles = currentElement.data('titles');
      if(!titles || titles.length < 1) {
        titles = ['Loading...']
      }

      var message = currentElement.find('.message');

      var titlesCounter = setInterval(function(){
        message.html(titles[randomIntFromInterval(0, titles.length - 1)]);
      }, 800);


      // animate progress bar
      var progressBar = currentElement.find('.progress-bar');
      var progress = randomIntFromInterval(0, 40);

      var progressCounter = setInterval(function(){
        progress += randomIntFromInterval(1, 3);
        progressBar.attr('aria-valuenow', progress).css('width', progress + '%');

        if(progress >= 200){
          clearInterval(progressCounter);
          clearInterval(titlesCounter);

          $(currentElement.data('to-hide')).slideUp(function() {
            $(currentElement.data('to-show')).slideDown();
          });
        }
      }, 25);

    });
  }

  function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  //
  // Events
  //
  if(autoLoader) {
    init();
  }

});
