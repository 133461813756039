import { createRequest, composeRequestConfig } from "../../core/utils/api";
import { formData } from "../../core/utils";

export const submitUserAssignment = (params, payload) => {
  const url = `/schools/${params.orgSlug}/classrooms/${params.classroomSlug}/assignments/${params.assignmentSlug}/work/${params.userAssignmentSlug}.json`;
  const config = composeRequestConfig({
    payload,
    method: 'patch',
    url,
  });

  return createRequest(config);
};

export const createAttachment = (params, file) => {
  const url = `/schools/${params.orgSlug}/classrooms/${params.classroomSlug}/assignments/${params.assignmentSlug}/work/${params.userAssignmentSlug}/attachments.json`;
  const payload = formData(file);
  
  const config = composeRequestConfig({
    payload,
    method: 'post',
    url,
  });

  return createRequest(config);
};

export const getAttachment = params => {
  const url = `/schools/${params.orgSlug}/classrooms/${params.classroomSlug}/assignments/${params.assignmentSlug}/work/${params.userAssignmentSlug}/attachments/${params.attachmentSlug}.json`;

  const config = composeRequestConfig({url});

  return createRequest(config);
};

export const destroyAttachment = params => {
  const url = `/schools/${params.orgSlug}/classrooms/${params.classroomSlug}/assignments/${params.assignmentSlug}/work/${params.userAssignmentSlug}/attachments/${params.attachmentSlug}.json`;

  const config = composeRequestConfig({ url, method: 'delete'});

  return createRequest(config);
};

export const download = params => {
  const url = `/schools/${params.orgSlug}/classrooms/${params.classroomSlug}/assignments/${params.assignmentSlug}/work/${params.userAssignmentSlug}/attachments/${params.attachmentSlug}/download.json`;

  const config = composeRequestConfig({ url });

  return createRequest(config);
};
