document.addEventListener("turbolinks:load", function(event) {
  if ($('body.posts.edit').length || $('body.assignments.edit').length || $('body.integrations_microsoft_teams_assignments.edit')) {
    $(document.body).on('click', '#scheduleSwitch', function(e) {
      var scheduled = document.getElementById("scheduleSwitch").checked;
      var scheduled_field = document.getElementById("collapse_schedule");
      var draft_button = document.getElementById("save_as_draft");

      if (scheduled) {
        add_default_date("publish_at_field");
        scheduled_field.hidden = false;
        if(draft_button) {
          draft_button.disabled = true;
        }
      } else {
        document.getElementById("publish_at_field").value = null;
        scheduled_field.hidden = true;
        if(draft_button) {
          draft_button.disabled = false;
        }
      }
    });

    $(document.body).on('click', '#pinSwitch', function(e) {
      var pinned = document.getElementById("pinSwitch").checked;
      var pinned_field = document.getElementById("collapse_pin_post");

      if (pinned) {
        add_default_date("pinned_until_field");

        pinned_field.hidden = false;
      } else {
        pinned_field.hidden = true;
        document.getElementById("pinned_until_field").value = null;
      }
    });

    var add_default_date = function(field_id) {
      document.getElementById(field_id).flatpickr({
        altInput: true,
        allowInput: false,
        altFormat: "F j, Y, H:i",
        dateFormat: "Y-m-d H:i",
        defaultDate: formatWithTime(new Date().fp_incr(7), '08:00'),
        enableTime: true,
        time_24hr: true,
        minDate: 'today'
      });
    }

    /**
     *
     * @param date{Date}
     * @param time{string} format is HH:MM
     * @returns {string}
     */
    var formatWithTime = function (date, time) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${time}`
    }
  }
});
