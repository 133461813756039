document.addEventListener("turbolinks:load", function (event) {
  if ($("#currently_sending_feedback").length) {
    var feedbackReload = window.setInterval(function () {
      if ($("#currently_sending_feedback").length) {
        Turbolinks.visit(window.location);
      } else {
        clearInterval(feedbackReload);
      }
    }, 60000);
  }
});