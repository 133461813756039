import Chart from 'chart.js/auto';

$(document).on('turbolinks:load', function() {

  var doughnut_charts = function() {

    $('.doughnut-chart').each(function(index, currentElement) {
      var currentElement = $(currentElement);

      new Chart(currentElement.find('.chart-canvas'), {
        type: 'doughnut',
        options: {
          legend: {
            display: true,
            position: 'bottom'
          },
          tooltips: {
            callbacks: {
              afterLabel: function() {
                return currentElement.data('after-label')
              }
            }
          }
        },
        data: {
          labels: currentElement.data('labels'),
          datasets: [{
            data: currentElement.data('values'),
            backgroundColor: currentElement.data('colors')
          }]
        }
      });

    });
  }

  var bar_charts = function() {
    $('.bar-chart').each(function(index, currentElement) {
      var currentElement = $(currentElement);

      new Chart(currentElement.find('.chart-canvas'), {
        type: 'bar',
        options: {
          legend: {
            display: false
          },
          scales: {
            y: {
              title: {
                display: true,
                text: 'Students',
              },
            },
            x: {
              title: {
                display: true,
                text: 'Percentage Grade'
              },
              label: 'yes'
            }
          }
        },
        data: {
          labels: currentElement.data('labels'),
          datasets: [{
            label: 'Grades',
            borderColor: '#2D7BE5',
            backgroundColor: "#2D7BE5",
            circular: true,
            data: currentElement.data('values')
          }]
        }
      });
    });
  }

  var line_charts = function() {
    $('.line-chart').each(function(index, currentElement) {
      var currentElement = $(currentElement);

      new Chart(currentElement.find('.chart-canvas'), {
        type: 'line',
        options: {
          legend: {
            display: true
          }
        },
        data: {
          labels: currentElement.data('labels'),
          datasets: [{
            label: 'Grade',
            data: currentElement.data('values'),
            borderColor: '#2D7BE5',
            backgroundColor: "#2D7BE5",
            tension: 0.3,
            fill: false
          },
          {
            label: 'Confidence',
            data: currentElement.data('valuess'),
            borderColor: '#149313',
            backgroundColor: "#149313",
            tension: 0.3,
            fill: false
          }]
        }
      });
    });
  }

  var init_charts = function() {
    doughnut_charts();
    bar_charts();
    line_charts();
  }

  init_charts();

});
