import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import WebViewer from '@pdftron/webviewer';
import ReactAudioPlayer from 'react-audio-player';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Alert
} from 'reactstrap';

import { getFileExtension } from '../../core/utils';
import { updateAnnotations } from '../Annotations/action';

const downloadFile = instance =>
  instance.downloadPdf({
    includeAnnotations: true,
    flatten: true,
  });

const handleOnSuccess = (element) => {
  const parentEl = document.getElementById('saving-span');
  element.textContent = 'Saved!'
  element.classList.add('text-success');
  parentEl.classList.remove('d-none');
};

const handleOnError = (element) => {
  const parentEl = document.getElementById('saving-span');

  element.textContent = 'Error saving... please try again?';
  element.classList.add('text-danger');
  parentEl.classList.remove('d-none');
};

const AttachmentPreviewer = ({ attachment, show, toggle }) => {
  const viewer = useRef(null);
  const [refVisible, setRefVisible] = useState(false);

  const {
    annotation,
    can_annotate,
    is_image,
    is_video,
    is_audio,
    annotatable,
    slug,
    name,
    view_url,
    current_user,
    download,
  } = attachment;

  const ext = getFileExtension(name);
  useEffect(() => {

    if (!refVisible) {
      return
    }

    WebViewer(
      {
        annotationUser: current_user.first_name,
        path: '/packs/js/assets/webviewer',
      },
      viewer.current,
    ).then((instance) => {
      instance.loadDocument(view_url, { extension: ext });
      instance.setReadOnly(can_annotate ? false : true);

      const docViewer = instance.docViewer;

      const { annotManager } = instance;

      docViewer.on('documentLoaded', () => {

        const el = document.getElementById('webviewer');
        el.classList.add("webviewer")

        // load the annotations
        const importAnnotations = annotManager.importAnnotations(annotation?.data);

        if (download) {
          downloadFile(instance);
        }

        annotManager.on('annotationChanged', (annotations, action, { imported }) => {
          // If the event is triggered by importing then it can be ignored
          // This will happen when importing the initial annotations from the server or individual changes from other users
          if (imported) return;

          const annot = annotations[0];
          annot.setCustomData('userId', current_user.id);
          annot.setCustomData('authorName', annotManager.getCurrentUser());

          const xfdfString = annotManager.exportAnnotations();
          xfdfString.then(data => updateAnnotations(data, slug, handleOnSuccess, handleOnError));
        });

        const mapNames = (annotation) => annotation.getCustomData('authorName');

        annotManager.setAnnotationDisplayAuthorMap(mapNames);
      });

    }).catch(err => console.error('err ', err));

  }, [refVisible])

  let modalBodyContent;

  if ((attachment.view_url && annotatable)) {
    modalBodyContent = <div ref={el => {
      viewer.current = el;
      setRefVisible(!!el);
    }} id="webviewer" />
  } else if (is_image) {
    modalBodyContent = <div className="webviewer"><img src={view_url} className="img-fluid" /></div>
  } else if (is_video) {
    modalBodyContent = <div className="embed-responsive embed-responsive-16by9 webviewer">
      <iframe
        allow="autoplay;fullscreen"
        className="embed-responsive-item"
        frameBorder="0"
        scrolling="no"
        allowFullScreen
        src={`https://onelineplayer.com/player.html?autoplay=false&autopause=false&muted=false&loop=false&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true&url=${encodeURIComponent(view_url)}`}
      >
      </iframe>
    </div>
  } else if (is_audio) {
    modalBodyContent = <div className="audio-column">
      <ReactAudioPlayer
        src={view_url}
        autoplay
        controls
      />
    </div>
  } else {
    <div className="container my-2 webviewer">
      <Alert color="warning">Oops! Looks like something went wrong. Try downloading the file directly instead.</Alert>
    </div>
  }

  return <Modal
    isOpen={show}
    toggle={toggle}
    modalClassName="fixed-right"
    className="modal-dialog-vertical"
  >
    <ModalHeader toggle={toggle} charCode="x" tag="h4" className="card-header-title">
      Attachment Preview {' '}
      <span className="text-muted font-weight-normal d-none" id="saving-span">
        <span className="mx-2">&bull;</span>
        <span className="mx-2" id="saving">Saved!</span>
      </span>
    </ModalHeader>
    <ModalBody> {modalBodyContent} </ModalBody>
  </Modal>
};

AttachmentPreviewer.propTypes = {
  attachment: PropTypes.shape({
    attributes: PropTypes.shape({
      view_url: PropTypes.string,
      annotatable: PropTypes.bool,
      is_image: PropTypes.bool,
      is_video: PropTypes.bool,
    })
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default AttachmentPreviewer;
