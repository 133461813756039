// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("tools/sentry")

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

require("general/magic")
require("general/filestack")
require("general/embed")
require("general/seen_by")
require("general/auto_loader")
require('moment')

require("general/google_classroom")
require("general/posts_form")
require("general/microsoft_teams")
require("general/form_warning")
require("general/auto_link")
require("general/charts")
require("general/clear_search_bar")

require("general/navigate_away_confirmation")

require("tools/emoji_picker")
require("tools/pdftron")

import ahoy from "ahoy.js"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
