document.addEventListener("turbolinks:load", function (event) {
  // Auto embed post links

  $('.embed_links a').each(function () {
    var content = this.innerHTML;
    var main_link = this;

    var providers = {
      youtube: {
        domains: ["youtube\\.com/watch.+v=[\\w-]+&?", "youtu\\.be/[\\w-]+", "youtube.com/embed"],
        templateRegex: /.*(?:v\=|be\/|embed\/)([\w\-]+)&?.*/,
        embedUrl: '//www.youtube-nocookie.com/embed/$1?wmode=transparent&modestbranding=1&rel=0&showinfo=0'
      },
      loom: {
        domains: ["loom\\.com/share/.+"],
        templateRegex: /.*share\/([^\?]+).*/,
        embedUrl: '//www.loom.com/embed/$1'
      },
      vimeo: {
        domains: ["vimeo.com/[0-9_\-]+"],
        templateRegex: /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i,
        embedUrl: '//player.vimeo.com/video/$1'
      }
    };

    $.each(providers, function (name, details) {
      var domains = details['domains'];

      $.each(domains, function (i, pattern) {
        if (content.match(pattern)) {
          var embed_url = content.replace(details['templateRegex'], details['embedUrl']);

          $(main_link).closest('p').append('<div class="embed-responsive embed-responsive-16by9 mt-3"><iframe class="embed-responsive-item" src="' + embed_url + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>');
        }
      });
    });

  });

});
