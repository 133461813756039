const formData = file => {
  const formData = new FormData();
  Object.keys(file).forEach(key => {
    formData.append(key, file[key]);
  });

  return formData;
};

export default formData;
