import { composeRequestConfig, createRequest } from "../../core/utils/api";

export const update = (attachmentSlug, data) => {
  const config = composeRequestConfig({
    url: `/annotations/${attachmentSlug}.json`,
    payload: { data },
    method: 'put',
  });

  return createRequest(config);
};
