import getFileExtension from "./file_extension";

import pngPlaceHolder from 'images/file_icons/png.svg';
import wordPlaceHolder from 'images/file_icons/word.svg';
import powerpointPlaceHolder from 'images/file_icons/powerpoint.png';
import excelPlaceHolder from 'images/file_icons/excel.png';
import jpgPlaceHolder from 'images/file_icons/jpg.svg';
import pdfPlaceHolder from 'images/file_icons/pdf.svg';
import movPlaceHolder from 'images/file_icons/mov.png';
import mp4PlaceHolder from 'images/file_icons/mp4.svg';
import audioPlaceHolder from 'images/file_icons/audio.png';
import defaultPlaceHolder from 'images/file_icons/default.png';

const fileTypeIcon = filename => {
  const fileTypes = {
    'png': pngPlaceHolder,
    'doc': wordPlaceHolder,
    'docx': wordPlaceHolder,
    'ppt': powerpointPlaceHolder,
    'pptx': powerpointPlaceHolder,
    'xls': excelPlaceHolder,
    'xlsx': excelPlaceHolder,
    'jpg': jpgPlaceHolder,
    'jpeg': jpgPlaceHolder,
    'pdf': pdfPlaceHolder,
    'mov': movPlaceHolder,
    'mp4': mp4PlaceHolder,
    'mp3': audioPlaceHolder,
    'wav': audioPlaceHolder,
    'ogg': audioPlaceHolder,
    'default': defaultPlaceHolder,
  };

  const ext = getFileExtension(filename);
  return fileTypes[ext] || fileTypes['default'];
};

export default fileTypeIcon;
