document.addEventListener("turbolinks:load", function(event) {

  // Sharing modal
  $(document.body).on('click', 'a[data-seen-by]', function(e) {
    e.preventDefault();

    var seen_by = $(this).data('seen-by');
    var not_seen_by = $(this).data('not-seen-by');

    // If seen by everyone, hide not seen tab
    if(not_seen_by.length == 0) {
      $('#not-seen-by-tab').hide();
    } else {
      $('#not-seen-by-tab').show();
      render_list('#not-seen-by-list', not_seen_by);
    }

    // if no one has seen it, hide seen by tab
    if(seen_by.length == 0) {
      $('#seen-by-tab').hide();
    } else {
      $('#seen-by-tab').show();
      render_list('#seen-by-list', seen_by);
    }

    // open modal
    $('#seenByModal').modal('show');

    // focus on the correct
    if(seen_by.length == 0) {
      $('#not-seen-by-tab').click();
    } else {
      $('#seen-by-tab').click();
    }
  });

  // clean up data in the modal
  // in case there is an error opening another modal but we are still
  // showing old data for something else
  $('#seenByModal').on('hidden.bs.modal', function (e) {
    $('#seen-by-list, #not-seen-by-list').html('');
  });

  var render_list = function(wrapper, data) {
    var seen_by_list = "";

    $.each(data, function(index, value) {
      seen_by_list += '<div class="col-md-6 mb-4">';
        seen_by_list += '<div class="row align-items-center">';

          seen_by_list += '<div class="col-auto">';
            seen_by_list += '<img class="avatar-img rounded-circle" style="width: 40px" src="' + value.avatar_url + '">';
          seen_by_list += '</div>';

          seen_by_list += '<div class="col ms-n2">';
            seen_by_list += '<h4 class="mb-1 name">' + value.name + '</h4>';
            seen_by_list += '<a href="javascript:void(0)" class="small mb-0 text-muted" data-bs-toggle="tooltip" title="' + value.desc_text_tip + '">';
              seen_by_list += value.desc_text;
            seen_by_list += '</a>';
          seen_by_list += '</div>';

        seen_by_list += '</div>';
      seen_by_list += '</div>';
    });

    $(wrapper).html(seen_by_list);
  }



  // Check if content has been viewed as users scroll past
  // https://css-tricks.com/a-few-functional-uses-for-intersection-observer-to-know-when-an-element-is-in-view/#use-case-3-see-how-much-content-is-viewed

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if(entry.isIntersecting){

        $.ajax({
          url: $(entry.target).data('track-view'),
          data: {viewed: true},
          dataType: "json"
        });

        observer.unobserve(entry.target);
      }
    });
  }, {threshold: 0.8});

  document.querySelectorAll('[data-track-view]').forEach(p => { observer.observe(p) });

});