import { update } from "./api";

export const updateAnnotations = async (data, attachmentSlug, onSuccess, onError) => {
  const el = document.getElementById('saving');
  el.textContent = 'Saving...';
  try {
    await update(attachmentSlug, data);
    if (typeof onSuccess === 'function') return onSuccess(el);
  } catch (error) {
    if (typeof onError === 'function') return onError(el);
  }
};
