import React from "react";
import PropTypes from 'prop-types';

const TextArea = React.forwardRef(({ onChange, onBlur, label, ...otherProps }, ref) => (
  <>
    <label htmlFor={label}>{label}</label>
    <textarea
      className="form-control"
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
      {...otherProps}
    />
  </>
));

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
};

export default TextArea;
