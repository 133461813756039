import React from 'react';
import { SnackbarProvider } from 'notistack'
import AssignmentTemplates from "nurture-react/assignmentTemplates/AssignmentTemplates";
import { CloseSnackBar } from "nurture-react/core/components";

const AssignmentTemplate = ({ token, user, query }) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));

  return (
      <div className="container Nurture-React mt-4">
        <SnackbarProvider
          classes={{containerRoot: "z-alert"}}
          autoHideDuration={8000}
          maxSnack={1}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          disableWindowBlurListener={true}
          action={<CloseSnackBar />}
        >
          <AssignmentTemplates baseQuery={query} />
        </SnackbarProvider>
      </div>
  )
}

export default AssignmentTemplate;
