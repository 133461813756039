import React from "react";
import PropTypes from 'prop-types';
import { PickerOverlay } from 'filestack-react';

import { filestack } from '../utils';

const clientOptions = {
  security: {
    policy: filestack.policy,
    signature: filestack.signature
  },
};

const FileUploader = ({ onUploadDone, onClose }) => {
  
  const pickerOptions = {
    fromSources: ['local_file_system', 'video', 'audio', 'webcam', 'googledrive', 'onedrive'],
    maxFiles: 20,
    onFileSelected: (file) => {
      if (file.size > 200000000) {
        alert(filestack.fileTooBig);
        throw new Error(filestack.fileTooBig);
      }
    },
    onFileUploadFailed: (file) => {
      alert(`There was an error uploading ${file.filename}. Try again or try a different file format?`);
    },
    onClose: () => onClose(),
  };

  return <PickerOverlay
    apikey={filestack.apiKey}
    onUploadDone={onUploadDone}
    clientOptions={clientOptions}
    pickerOptions={pickerOptions}
  />;
};

FileUploader.propTypes = {
  onUploadDone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FileUploader;
