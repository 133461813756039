import { EmojiButton } from '@joeattardi/emoji-button';

document.addEventListener("turbolinks:load", function(event) {
  const emoji_trigger = document.querySelector('.emoji_picker');

  if(emoji_trigger) {
    const emoji_picker = new EmojiButton();

    emoji_picker.on('emoji', selection => {
      emoji_trigger.value = selection.emoji;
    });

    emoji_trigger.addEventListener('click', () => emoji_picker.togglePicker(emoji_trigger));
  }
});
