import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

const DeleteModal = ({ show, onDelete, toggle, text }) => (
  <Modal isOpen={show} toggle={toggle}>
    <ModalHeader toggle={toggle} charCode="x" tag="h4">Confirm Delete</ModalHeader>
    <ModalBody>
      {text ? text : 'Are you sure you want to delete this item?'}
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
      <Button color="danger" onClick={onDelete}>Delete</Button>
    </ModalFooter>
  </Modal>
);

DeleteModal.defaultProps = {
  text: undefined
};

DeleteModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  text: PropTypes.string,
}

export default DeleteModal;
