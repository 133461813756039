const isNumeric = number => !Number.isNaN(Number.parseFloat(number)) && Number.isFinite(number);

const numberToHumanSize = size => {
  if (size <= 0 || !isNumeric(parseFloat(size))) return;

  const floatSize = parseFloat(size);

  if (floatSize < 1024) return Math.round(floatSize) + ' bytes';

  if (floatSize < 1024.0 * 1024.0) return (floatSize / 1024.0).toFixed(2) + ' KB';

  if (floatSize < 1024.0 * 1024.0 * 1024.0) return (floatSize / 1024.0 / 1024.0).toFixed(2) + ' MB';

  return (floatSize / 1024.0 / 1024.0 / 1024.0).toFixed(2) + ' GB';
};

export default numberToHumanSize;
