import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

import { TextArea } from '../../core/form/fields';
import { isPresent, validUrl, filestack } from '../../core/utils';
import { Alert, FileUploader } from '../../core/components';

import AttachmentSection from './AttachmentSection';
import StudentConfidence from './StudentConfidence';

import { addAttachment, submitAssignment } from './actions';

const confidenceOptions = {
  1: ['😓', 'Low'],
  2: ['😊', 'Neutral'],
  3: ['😎', 'High']
};

const schema = yup.object().shape({
  'student_notes': yup.string().required(),
  'student_confidence': yup.number().oneOf([1, 2, 3]).required(),
});

const Form = ({ orgSlug, classroomSlug, assignmentSlug, userAssignment, embeddableHint }) => {

  const defaultValues = {
    student_notes: userAssignment.student_notes || '',
    student_confidence: userAssignment.student_confidence || '',
  };

  const [isPickerOverlayVisible, setIsPickerOverlayVisible] = useState(false);
  const [userAssignmentAttachments, setUserAssignmentAttachments] = useState(userAssignment.attachments);
  const [studentNotesHasUrl, setStudentNotesHasUrl] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertColor, setAlertColor] = useState('dark');

  const closeAlert = () => setShowAlert(false);

  const { control, handleSubmit, formState: { errors }, setValue, watch, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const paramSlugs = {
    orgSlug,
    classroomSlug,
    assignmentSlug,
    userAssignmentSlug: userAssignment.slug,
  };

  useEffect(() => {
    if (validUrl(watchStudentNotes) || userAssignmentAttachments.length) {
      setStudentNotesHasUrl(true);
    } else {
      setStudentNotesHasUrl(false);
    }
  }, []);

  useEffect(() => {
    if (studentNotesHasUrl || userAssignmentAttachments.length) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [userAssignmentAttachments, studentNotesHasUrl]);

  const onSubmit = async data => {
    setDisabled(true)
    const values = {
      user_assignment: {
        ...data
      },
    };

    try {
      await submitAssignment(paramSlugs, values);
      goBackToAssignmentsPage();
    } catch (error) {
      setShowAlert(false);
      setAlertText('Oops! Have you uploaded an attachment, added a personal note and your confidence level?');
      setAlertColor('danger');
      setShowAlert(true);
      setDisabled(false);
    }
  };

  const onUploaderButtonClick = () => setIsPickerOverlayVisible(!isPickerOverlayVisible);

  const handleOnDelete = async deletedAttachment => {
    const updatedAttachments = userAssignmentAttachments.filter(attachment => attachment.id !== deletedAttachment.id);
    setUserAssignmentAttachments(updatedAttachments);
  };

  const handleUploadDone = async upload => {
    try {
      for (const file of upload.filesUploaded) {
        const response = await addAttachment({ userAssignmentSlug: userAssignment.slug, orgSlug, classroomSlug, assignmentSlug }, file);
        setUserAssignmentAttachments(userAssignmentAttachments => [...userAssignmentAttachments, response]);
      }
      setIsPickerOverlayVisible(false);
    } catch (error) {
      alert(filestack.error);
    }
  };

  const handleFileStackClose = () => setIsPickerOverlayVisible(false);

  const goBackToAssignmentsPage = () =>
    window.location.replace(`/schools/${orgSlug}/classrooms/${classroomSlug}/assignments/${assignmentSlug}`);

  const handleStudentNotesChange = () => {
    const isValidUrl = validUrl(watchStudentNotes);
    if (isValidUrl) {
      setStudentNotesHasUrl(true);
    } else {
      setStudentNotesHasUrl(false);
    }
  };

  const watchStudentNotes = watch('student_notes', userAssignment.student_notes);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="card">
        <div className="card-body">

          <div className="mb-3">
            <Controller
              name="student_notes"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextArea
                  ref={ref}
                  label="Notes"
                  rows="2"
                  value={value}
                  onChange={onChange}
                  onKeyUp={() => handleStudentNotesChange()}
                  placeholder="Use this space to explain how long this assignment took to complete, how easy or difficult it was, etc."
                  onBlur={onBlur}
                />
              )}
            />
            {errors.student_notes && (
              <small className="form-text text-danger mt-1">Please fill in the student notes</small>
            )}
            <small className="form-text text-muted mt-1">Briefly describe how long you spent on this assignment and how easy or difficult you found it.</small>
            <small className="form-text text-muted" dangerouslySetInnerHTML={{ __html: embeddableHint }} />
          </div>

          <div className="mb-3">
            <div className="mb-3">
              <label className="mb-0 d-block">How confident were your answers?</label>
              <small className="text-muted">
                How well would you rate your confidence for this assignment?
              </small>
            </div>
            <div className="d-grid">
              <div className="btn-group btn-group-toggle" role="group" data-toggle="buttons">
                <StudentConfidence userAssignment={userAssignment} setValue={setValue} />
                {errors.student_confidence && (
                    <small className="form-text text-danger mt-1">Please choose a confidence level</small>
                )}
              </div>
            </div>

            <div className="attachments-list edit">
              {isPresent(userAssignmentAttachments) && userAssignmentAttachments.map(attachment =>
                <AttachmentSection key={attachment.slug} onDelete={handleOnDelete} params={paramSlugs} attachment={attachment} />
              )}
            </div>
          </div>
        </div>
        <Alert
          text={alertText}
          open={showAlert}
          visible={showAlert}
          toggle={closeAlert}
          variant={alertColor}
        />

        {isPickerOverlayVisible && <FileUploader onUploadDone={handleUploadDone} onClose={handleFileStackClose} />}

        <div className="card-footer">
          <div className="row">
            <div className="col">
              <button type="button" className="btn btn-outline-primary upload-files" onClick={onUploaderButtonClick}>
                <i className="fe fe-paperclip me-1" />
                Add attachments
              </button>

            </div>
            <div className="col-auto">
              {userAssignment.status === 'submitted' && (
                <button type="button" className="btn btn-white me-2" onClick={goBackToAssignmentsPage}>Cancel</button>
              )}
              <button type="submit" className="btn btn-primary" disabled={disabled}>Submit Assignment</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

Form.propTypes = {
  orgSlug: PropTypes.string.isRequired,
  classroomSlug: PropTypes.string.isRequired,
  assignmentSlug: PropTypes.string.isRequired,
  userAssignment: PropTypes.shape({}).isRequired,
};

export default Form;
