import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

try {
  if (Sentry.getCurrentHub().getClient() !== undefined) {
    Sentry.captureException('Sentry already initialized');
  } else {
    Sentry.init({
      dsn: "https://c2c0da27eab34f939c461b68e35e695e@o1018463.ingest.sentry.io/6044715",
      integrations: [new Integrations.BrowserTracing()],

      tracesSampleRate: 0.25,
      allowUrls: [
        /https?:\/\/(app.gonurture|school.jumpagrade)\.com/
      ]
    });
  }
} catch (e) {
  if (Sentry.getCurrentHub().getClient() !== undefined) {
    console.error(e);
  } else {
    console.error(e);
  }
}
