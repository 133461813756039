import React, { useState } from "react";
import PropTypes from 'prop-types';
import { saveAs } from "file-saver";

import { isPresent, numberToHumanSize, validPdfTronFormat, fileTypeIcon } from "../../core/utils";

import { fetchAttachment, deleteAttachment, downloadAttachment } from "./actions";
import AttachmentPreviewer from "../modal/AttachmentPreviewer";
import DeleteModal from "../modal/DeleteModal";
import { Alert } from "../../core/components";

const AttachmentSection = ({ onDelete, params, attachment }) => {
  const [disabled, setDisabled] = useState(false);
  const [modfiedImage, setModfiedImage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertColor, setAlertColor] = useState('dark');
  
  const closeAlert = () => setShowAlert(false);

  const { slug, name } = attachment;

  const fullParams = {
    ...params,
    attachmentSlug: slug,
  };

  const filePlaceholder = fileTypeIcon(name);

  const toggleModal = () => setShowModal(!showModal);

  const handleOnView = async () => {
    setDisabled(true);
    try {
      const data = await fetchAttachment(fullParams);
      setModfiedImage(data);
      setDisabled(false);
      setShowModal(true);
    } catch (error) {
      setShowAlert(false);
      setAlertText('Unable to view attachment 😢. Please try again.');
      setAlertColor('danger');
      setShowAlert(true);
    }
  };

  const handleOnDelete = async () => {
    try {
      await deleteAttachment(fullParams);
      onDelete(attachment);
      setShowDeleteModal(false);
    } catch (error) {
      setShowAlert(false);
      setAlertText('Unable to delete attachment 😢. Please try again later');
      setAlertColor('danger');
      setShowAlert(true);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await downloadAttachment(fullParams);
      saveAs(response, name);
    } catch (error) {
      setShowAlert(false);
      setAlertText('Unable to download attachment 😢. Please try again later');
      setAlertColor('danger');
      setShowAlert(true);
    }
  };

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const openDeleteModal = () => setShowDeleteModal(true);

  return (<>
    <Alert
      text={alertText}
      open={showAlert}
      visible={showAlert}
      toggle={closeAlert}
      variant={alertColor}
    />
    <DeleteModal
      show={showDeleteModal}
      onDelete={handleOnDelete}
      toggle={toggleDeleteModal}
    />
  
    {isPresent(modfiedImage) && (
      <AttachmentPreviewer
        attachment={modfiedImage}
        show={showModal}
        toggle={toggleModal}
      />
    )}


    <div className="card attachment mb-0 mt-3">
      <div className="card-body">
        <div className="row">
          <div className="col-auto ps-0 mb-3 pt-4">
            <img src={filePlaceholder} height={40} />
          </div>

          <div className="col ms-n2">
            <h4 className="mb-0">{attachment.name}</h4>
            <p className="card-text small text-muted mb-3 mt-2">
              {numberToHumanSize(attachment.filestack_info.size)}
            </p>

            {validPdfTronFormat(attachment.name) && (
              <button type="button" onClick={handleOnView} className="btn btn-primary btn-sm me-1" disabled={disabled}>
                View
              </button>
            )}

            <button type="button" className="btn btn-sm btn-outline-primary" onClick={handleDownload}>
              Download
              <i className="fe fe-download ms-2" />
            </button>
          </div>

          <div className="col-auto pt-4">
            <button type="button" onClick={openDeleteModal} className="btn btn-link text-danger">
              <i className="fe fe-x" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

AttachmentSection.propTypes = {
  onDelete: PropTypes.func.isRequired,
  attachment: PropTypes.shape({}).isRequired,
};

export default AttachmentSection;
