import React from 'react';
import { SnackbarProvider } from 'notistack'
import AssignmentTemplateEdit from "nurture-react/assignmentTemplates/AssignmentTemplateEdit";
import { CloseSnackBar } from "nurture-react/core/components";

const Edit = ({ token, id, user }) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));

    return (
        <div className="container Nurture-React mt-4">
            <SnackbarProvider
                classes={{containerRoot: "z-alert"}}
                autoHideDuration={8000}
                maxSnack={1}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                disableWindowBlurListener={true}
                action={<CloseSnackBar />}
            >
                <AssignmentTemplateEdit assignmentTemplateId={id} />
            </SnackbarProvider>
        </div>
    )
}

export default Edit;