import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

const AlertPopup = ({ visible, toggle, variant, text}) => {

  return (
    <div>
      <Alert isOpen={visible} toggle={toggle} color={variant} className="alert-popup">
        <p className="text text-light">{text}</p>
      </Alert>
    </div>
  );
};

AlertPopup.defaultProps = {
  visible: false,
  variant: 'dark',
};

AlertPopup.propTypes = {
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['primary','secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'])
}

export default AlertPopup;
